import React from "react"
import Header from "./header"
import Footer from "./footer"
import SeoHelmet from "./seo-helmet";
import { graphql, useStaticQuery } from "gatsby";
export default function Layout({ profileData, gravatar, children, pageTitle }) {
    let profile = null;
    let gravatarData = null;
    const title = pageTitle ? `${profileData.name} - ${pageTitle}` : profileData.siteTitle;

    const data = useStaticQuery(query);
    if (!profileData || !gravatar) {
        profile = data.allContentfulProfile.nodes[0];
        gravatarData = data.gravatar;
    }
    else {
        profile = profileData;
        gravatarData = gravatar
    }


    return (
        <div class="mb-5 md:mx-3 mx-1 md:mx-0" >
                  <div className="info-banner">
  <p>Coming soon. joshuaghali.com is under going a new look with more content and a better design.</p>
</div>
            <SeoHelmet profileData={profile} gravatar={gravatar} pageTitle={title} />
            <Header profileData={profile} gravatar={gravatarData}></Header>
      

            {children}
            <Footer></Footer>

        </div >

    )
}

const query = graphql`
        {
            allContentfulProfile(limit: 1, filter: {node_locale: {eq: "en-US"}}) {
                nodes {
                    headerTitle
                    node_locale
                    name
                    siteTitle
                    skills {
                        raw
                    }
                   aboutMe {
                        raw
                    }
                }
            }            
            gravatar {
                url
            }
        }
        `;

