import * as React from "react"
import { graphql, useStaticQuery } from "gatsby";
import { globalHistory } from "@reach/router"
import Layout from "../../components/layout";


const IndexPage = () => {

  const data = useStaticQuery(query);
  const profileData = data.allContentfulProfile.nodes[0];
  const gravatar = data.gravatar;
  const articles = data.allSitePage.nodes;

  return (
    <Layout profileData={profileData} gravatar={gravatar} pageTitle={"devlog"}>

      <div className="devlog-article bg-dark-gray w-full min-h-screen">
        <div className="w-full max-w-6xl mx-auto mt-2 flex justify-between md:flex-no-wrap flex-wrap md:px-16">
          {
            articles.map((item, index) => {
              let path = item.path;
              if (item.pageContext.hasOwnProperty('data') && item.path.includes("devlog/")) {
                let pageContext = item.pageContext.data;
                const categoriesList = pageContext.categories.map((item) => {
                  return item.name + " ";
                })
                if (pageContext.node_locale !== "en-US")
                  return "";
                return (
                  <div className="md:my-4 my-2 w-full">
                    <h1 class="section-header font-medium md:text-3xl text-2xl">
                      <a href={path}>
                        {pageContext.title}
                      </a>
                    </h1>
                    <h2 class="section-header font-medium md:text-base text-sm">
                      {pageContext.publicationDate} {
                        categoriesList}
                    </h2>
                  </div>
                );
              }
            })
          }
        </div >
      </div >
    </Layout>
  )
}

const query = graphql`
        {
           allContentfulProfile(limit: 1, filter: {node_locale: {eq: "en-US"}}) {
                nodes {
                    headerTitle
                    node_locale
                    name
                    siteTitle
                    skills {
                        raw
                    }
                   aboutMe {
                        raw
                    }
                }
            }
            allContentfulSocialLinks(filter: {node_locale: {eq: "en-US"}}) {
              nodes {
                name
                url
              }
            }
            allSitePage {    
                nodes {
                  path
                  pageContext
                }
            }
            gravatar {
              url
            }
            allContentfulCertifications {
                edges {
                  node {
                    issuer
                    issueDate
                    issuerWebsite
                    title
                    description {
                      raw
                    }
                  }
                }
            } 
}`;

export default IndexPage
